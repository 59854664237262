<template>
	<div class="kuaiXunWraper padding_120 kuai_pc el-step-pc">
		<div class="flex main-box justify_content_sb" style="padding-bottom: 20px">
			<div class="MainLeft MainLeft_760 MainLeft_right_30">
				<div
					class="steps kxsteps"
					v-if="newarticleList.length > 0 || !isNetRequestBool"
				>
					<div v-for="(items, indexs) in newarticleList" :key="indexs">
						<div class="flex align_items_c date-box sticky-div">
							<div class="kxDate">{{ items.release_time }}</div>
							<div
								class="kxDate_co"
								style="margin-left: 10px"
								v-if="items.diff_time != null"
							>
								<div class="kxDate_diff" style="font-size: 12px; color: #fff">{{
									items.diff_time
								}}</div>
								<div
									class="kxDate_week"
									style="font-size: 12px; color: #fff; opacity: 0.5"
									>{{ items.week_time }}</div
								>
							</div>
							<div
								class="kxDate_week"
								style="font-size: 18px; color: #fff; margin-left: 10px"
								v-else
								>{{ items.week_time }}</div
							>
						</div>
						<el-steps
							direction="vertical"
							:active="1"
							style="padding-top: 40px"
						>
							<el-step
								v-for="(item, index) in items.subList"
								:key="index"
								class="kx_pc_item"
							>
								<template slot="description">
									<div class="contentBox">
										<router-link
											target="_blank"
											:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
										>
											<div
												class="stepsVal ellipsis_multiple pointer hovercolor"
												:prohibitTran="item.prohibit_tran"
												:style="{ color: item.isRecommend ? '#1545D6' : '' }"
											>
												{{
													localLanguage == 2
														? item.title
															? item.title
															: item.title
														: item.title
												}}
											</div>
										</router-link>
										<p
											class="contentStep"
											:prohibitTran="item.prohibit_tran"
											v-html="
												localLanguage == 2
													? item.content
														? item.content
														: item.content
													: item.content
											"
										></p>

										<div class="kxstepsBox">
											<div class="stepsTime" v-if="item.release_time != null">
												<a v-if="item.url" target="_blank" :href="item.url"
													>[原文链接]</a
												>
												{{ item.release_time.substr(10, 6) }}
												<!-- 这里的数据是不是放在下面的不知道 -->
												{{
													item.source_type == 2 || item.source_type == 10
														? " 律动BlockBeats"
														: item.source_type == 3
														? " Odaily星球日报"
														: item.source_type == 4
														? " PANews"
														: item.source_type == 6
														? " Followin"
														: item.source_type == 7
														? " 吴说区块链"
														: item.source_type == 11
														? " 深潮TechFlow"
														: ""
												}}
											</div>
											<div class="flex align_items_c kxstepsBoxLeft">
												<div class="author">{{ item.author.nickname }}</div>
												<!-- <div
													class="flex align_items_c btnitem justify_content_c fx pointer"
													@click="showWeChat(item.id)"
												>
													<img
														src="https://res.metaera.hk/resources/assets/images/kxwz/kxwz_wechat_w.svg"
														alt=""
													/>
												</div> -->
												<div
													class="flex align_items_c btnitem justify_content_c fx pointer"
													@click="twitterText(item.id, item.title)"
												>
													<img
														src="https://res.metaera.hk/resources/assets/images/kxwz/tui_w.svg"
														alt=""
													/>
												</div>
												<div
													class="flex align_items_c btnitem justify_content_c fx pointer"
													@click="showPreView(item.id)"
												>
													<img
														src="https://res.metaera.hk/resources/assets/images/kxwz/link_w.svg"
														alt=""
													/>
												</div>
											</div>
										</div>
										<div class="kx_line"></div>
									</div>
								</template>
							</el-step>
						</el-steps>
					</div>
					<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
				</div>
			</div>
			<div class="MainRight MainRight_400">
				<!-- sticky-div 不要粘性定位 -->
				<div class="">
					<module-part type="4"> </module-part>
					<module-part type="5"> </module-part>
					<!-- <resou></resou> -->
					<!-- 24H 重要资讯 -->
					<!-- <zixun
						:partName="'24H 重要资讯'"
						:selarticleList="newarticleList"
					></zixun> -->
					<download></download>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { categoryLists, getArticleList } from "@/api/home";
import { myMixin } from "@/utils/mixin.js";
import clipboard from "clipboard";
// import zixun from "./../../components/zixun.vue";
import modulePart from "@/components/modulePart.vue";
import download from "./../../components/pc/download.vue";
import { newBus } from "../../components/pc/topHeader2.vue";
import ChineseConv from "chinese-s2t";

export default {
	mixins: [myMixin],
	components: { download, modulePart },
	name: "",
	data() {
		return {
			lookTitle: false,
			categoryLists: [],
			articleList: [],
			active: 0,
			newarticleList: [],
			page: 1,
			size: 25,
			total: "",
			isNetRequestBool: false,
			loading: false,
			currentId: 0,
			localLanguage: 1,
			// currentItems: {}, // 左侧列表当前展示的对应的那条数据
		};
	},
	created() {
		this.getArticleList(
			{
				category_id: 0,
				type: 2,
				page: this.page,
				size: this.size,
			},
			0
		);
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		showWeChat(id) {
			this.currentId = id;
		},
		showPreView(id) {
			let msg = "https://web.metaera.hk/article/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		twitterText(id, title) {
			// let msg = "https://web.metaera.hk/article/" + id;
			// clipboard.copy(msg);
			// this.$message({ message: "复制成功", type: "success" });
			var sharetitle = ChineseConv.s2t(title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/article/" + id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		copyText(id, title) {
			// let msg = "https://web.metaera.hk/article/" + id;
			// clipboard.copy(msg);
			// this.$message({ message: "复制成功", type: "success" });
			var sharetitle = ChineseConv.s2t(title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/article/" + id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList(
				{
					category_id: 0,
					type: 2,
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getDataList() {
			categoryLists({ type: 2 })
				.then((res) => {
					if (res.code == 200) {
						this.getArticleList(
							{
								category_id: 0,
								type: 2,
								page: this.page,
								size: this.size,
							},
							0
						);
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		handleClick(item, index) {
			this.page = 1;
			this.active = index;
			this.getArticleList(
				{ category_id: item.id, type: 2, page: this.page, size: this.size },
				0
			);
		},
		getArticleList(obj, type) {
			let res = JSON.parse(sessionStorage.getItem("getKXArticleList1Data"));
			if (res != null && res.code == 200 && obj.page == 1) {
				this.analyzeData(res, obj);
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						if (obj.page == 1) {
							sessionStorage.setItem(
								"getKXArticleList1Data",
								JSON.stringify(res)
							);
						}
						this.analyzeData(res, obj);
					} else {
						// this.$message.error(res.message);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		analyzeData(res, obj) {
			if (res.code == 200) {
				this.loading = false;
				this.articleList = res.data.list;
				this.total = res.data.total;
				if (obj.page == 1) {
					this.newarticleList = [];
				}
				this.newarticleList = this.getNewData(
					this.articleList,
					this.newarticleList
				);
				// this.currentItems = this.newarticleList[0];
				this.isNetRequestBool = true;
				// console.log(this.newarticleList);
			} else {
			}
		},
		getYearMonth(date) {
			// 将日期以空格隔开，即['2020-06-13', '17:10:09']
			date = (date + "").split(/[ ]+/);
			let result = "";
			let reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
			// 用截取出来的年月日进行正则表达式匹配
			reg.exec(date[0]);
			result = RegExp.$1 + "-" + RegExp.$2 + "-" + RegExp.$3;

			return result;
		},

		handleNotice() {
			let that = this;
			if (!("Notification" in window)) {
				alert("此浏览器不支持桌面通知！");
			}
			Notification.requestPermission().then(function (result) {
				if (result === "denied") {
					// console.log("被拒绝");
					return;
				}
				if (result === "default") {
					// console.log("默认");
					return;
				}
				that.notifyMsg();
			});
		},
		notifyMsg() {
			//通知
			const title = "通知栏";
			const options = {
				body: "这是消息主体", // 通知主体
				data: "",
				icon: "https://sponsors.vuejs.org/images/chrome_frameworks_fund.png", // 通知图标
			};
			let notification = new Notification(title, options);
			notification.onclick = () => {
				notification.close(); //关闭通知
			};
		},
	},
};
</script>
<style>
.contentStep,
.contentStep p,
.contentStep p span,
.contentStep p a {
	margin-top: 10px;
	text-align: justify;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(8px, calc(100vw * 14 / 1440), 14px) !important;
	line-height: clamp(12px, calc(100vw * 24 / 1440), 24px) !important;
	font-weight: normal;
	letter-spacing: 0em;

	color: #fff !important;
}
.contentStep {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	color: green;
}
.contentStep img {
	display: none;
}
.kx_line {
	width: 100%;
	height: 1px;
	margin-bottom: 20px;
}
</style>

<style scoped>
.kuai_pc {
	padding: 0 calc(100vw * 120 / 1440);
}
.main-box {
	padding-top: calc(100vw * 30 / 1440);
	/* border-top: 1px solid rgba(212, 216, 216, 0.2); */
}
.date-box {
	padding-bottom: 10px;
	background: #121212;
	opacity: 1;
}
.MainLeft {
	border-right: 1px solid rgba(216, 216, 216, 0.4);
}

.kxTime {
	width: 100%;
}

.kxDate {
	font-size: 41px;
	line-height: 53px;
	font-family: DINPro-Medium, DINPro;
	font-weight: 500;
	color: #fff;
}

.kxweek {
	margin-left: 7px;
}

.kxweek div:nth-child(1) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.kxweek div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
}

/* .tabList {
        margin-left: 21px;
    } */
.MainRight_2 {
	background: rgba(255, 255, 255, 0);
	opacity: 1;
	padding-left: 0px;
}
.navItem {
	margin-left: 34px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #767680;
}

.setTitle div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	margin-right: 5px;
}

.setTitle img {
	width: 18px;
	height: 18px;
}

.btnitem {
	width: 23px;
	height: 23px;
	border-radius: 12px;
	font-size: 12px;
	display: flex;
	margin-left: 10px;
}

.btnitem img {
	width: 14px;
	/* height: 18px; */
	margin-right: 6px;
}

.btnitemimg2 {
	height: 20px !important;
	width: auto !important;
}
.btnitem div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
}

.kxstepsBox {
	margin-top: 18px;
	margin-bottom: 2px;
	/* display: flex; */
	/* justify-content: space-between; */
}

.kxstepsBoxLeft {
	margin-top: calc(100vw * 20 / 1440);
	width: auto;
}
.kxstepsBoxLeft .author {
	font-size: calc(100vw * 12 / 1440);
	line-height: calc(100vw * 13 / 1440);
	color: #b2b2b2;
}
.kxstepsBox a {
	font-size: clamp(8px, calc(100vw * 14 / 1440), 14px);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}
.kxstepsBox a::before {
	content: "";
	position: absolute;
	bottom: -2px;
	width: 100%;
}
.active {
	color: #0056ff;
}
.setting {
	position: relative;
}

.setting img {
	display: block;
	cursor: pointer;
}

.setting .setting__list {
	display: block;
	position: absolute;
	left: -56px;
	top: 40px;
	z-index: 10;
	width: 118px;
	padding: 9px 13px;
	border-radius: 4px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
	background: #fff;
	box-sizing: border-box;
}

.setting .setting__list li {
	display: block;
	height: 16px;
	padding: 5px 0;
}

.setting .setting__list li span {
	float: left;
	font-size: 14px;
	color: #666;
	line-height: 14px;
}

.switch {
	float: right;
	width: 24px;
	height: 13px;
	padding: 1px;
	border-radius: 8px;
	color: #666;
}

.switch i {
	display: block;
	width: 11px;
	height: 11px;
	background: #fff;
	border-radius: 50%;
	margin: 1px 2px;
}

.switch.remind {
	background: #dedee1;
}

.switch.remind-open {
	background: #ffb100;
}

.switch.remind-open i {
	float: right;
}

.js-lives__box .js-lives__date {
	float: inherit;
	margin: 45px 0;
}
.stepsTime {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(8px, calc(100vw * 14 / 1440), 14px);
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	color: #fff;
	margin-top: 4px;
}
</style>
<style scoped>
.sticky-div {
	position: sticky;
	top: 100px;
	z-index: 2;
}
</style>
<style scoped>
.kuaiXunWraper .el-step.is-vertical .el-step__line {
	top: 0px !important;
	/* margin-top: 10px; */
}
.kuaiXunWraper .el-step__description {
	margin-top: 0;
}

.kuaiXunWraper .el-step__icon {
	top: -10px;
}

.kuaiXunWraper .el-step__main {
	padding-top: 0px;
	margin-top: -15px;
}
.kx_pc_item {
	margin-left: 20px;
	margin-top: 0px;
	/* margin-right: 25px; */
}
</style>
